import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Chip,
  Tooltip,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Construction } from '@mui/icons-material';

const Header = () => {
  const navigate = useNavigate();
  const isLoggedIn = !!localStorage.getItem('tokens');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleLogout = () => {
    localStorage.removeItem('tokens');
    navigate('/login');
  };

  return (
    <AppBar position="static" elevation={0} sx={{ 
      backgroundColor: 'var(--white)', 
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      position: 'sticky',
      top: 0,
      zIndex: 1000,
      backdropFilter: 'blur(10px)',
      WebkitBackdropFilter: 'blur(10px)'
    }}>
      <Toolbar sx={{ maxWidth: 1200, width: '100%', margin: '0 auto', padding: '0.5rem 1rem' }}>
        <Typography 
          variant="h6" 
          component="div" 
          sx={{ 
            flexGrow: 1, 
            fontFamily: 'var(--heading-font)',
            fontWeight: 700,
            fontSize: '1.5rem',
            background: 'linear-gradient(135deg, var(--primary-blue), var(--primary-green))',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            backgroundClip: 'text',
            color: 'transparent',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
            '&:hover': {
              transform: 'scale(1.05)',
              background: 'linear-gradient(135deg, var(--primary-green), var(--primary-blue))',
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text'
            }
          }}
          onClick={() => navigate('/')}
        >
          Flashckard
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: isMobile ? 0.5 : 1 }}>
          {isLoggedIn ? (
            <>
              <Button 
                color="inherit" 
                onClick={() => navigate('/dashboard')}
                sx={{
                  color: 'var(--dark-gray)',
                  fontWeight: 500,
                  transition: 'color 0.3s ease',
                  '&:hover': {
                    color: 'var(--primary-blue)',
                    backgroundColor: 'transparent'
                  },
                  padding: isMobile ? '4px 8px' : '6px 12px',
                  minWidth: isMobile ? 'auto' : '64px'
                }}
              >
                Dashboard
              </Button>
              <Button 
                color="inherit" 
                onClick={() => navigate('/flashcards')}
                sx={{
                  color: 'var(--dark-gray)',
                  fontWeight: 500,
                  transition: 'color 0.3s ease',
                  '&:hover': {
                    color: 'var(--primary-blue)',
                    backgroundColor: 'transparent'
                  },
                  padding: isMobile ? '4px 8px' : '6px 12px',
                  minWidth: isMobile ? 'auto' : '64px'
                }}
              >
                Flashcards
              </Button>
              <Tooltip title="Chat feature is under development" arrow>
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  <Button 
                    color="inherit" 
                    disabled
                    startIcon={<Construction />}
                    sx={{
                      opacity: 0.8,
                      '&.Mui-disabled': {
                        color: 'var(--dark-gray)'
                      },
                      padding: isMobile ? '4px 8px' : '6px 12px',
                      minWidth: isMobile ? 'auto' : '64px'
                    }}
                  >
                    {!isMobile && 'Chat'}
                  </Button>
                </Box>
              </Tooltip>
              <Button 
                onClick={handleLogout}
                sx={{
                  backgroundColor: 'var(--primary-blue)',
                  color: 'var(--white)',
                  padding: isMobile ? '4px 10px' : '6px 16px',
                  borderRadius: '8px',
                  fontWeight: 600,
                  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                  position: 'relative',
                  overflow: 'hidden',
                  '&:hover': {
                    backgroundColor: 'var(--dark-blue)',
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 8px rgba(0,123,255,0.2)'
                  },
                  '&:active': {
                    transform: 'translateY(1px)'
                  }
                }}
              >
                Logout
              </Button>
            </>
          ) : (
            <>
              <Button 
                color="inherit" 
                onClick={() => navigate('/login')}
                sx={{
                  color: 'var(--dark-gray)',
                  fontWeight: 500,
                  transition: 'color 0.3s ease',
                  '&:hover': {
                    color: 'var(--primary-blue)',
                    backgroundColor: 'transparent'
                  }
                }}
              >
                Login
              </Button>
              <Button 
                onClick={() => navigate('/register')}
                sx={{
                  backgroundColor: 'var(--primary-blue)',
                  color: 'var(--white)',
                  padding: '6px 16px',
                  borderRadius: '8px',
                  fontWeight: 600,
                  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                  position: 'relative',
                  overflow: 'hidden',
                  '&:hover': {
                    backgroundColor: 'var(--dark-blue)',
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 8px rgba(0,123,255,0.2)'
                  },
                  '&:active': {
                    transform: 'translateY(1px)'
                  }
                }}
              >
                Sign Up
              </Button>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header; 