import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Paper,
  useTheme,
  CircularProgress,
  Alert,
  Container
} from '@mui/material';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import StatsCard from './StatsCard';
import api from '../../services/api';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState({
    daily_stats: [],
    total_stats: {
      total_cards: 0,
      total_starred: 0,
      total_sources: 0,
      streak_days: 0
    }
  });

  const fetchStats = async () => {
    try {
      setIsLoading(true);
      setError(null);

      // Get stats for the last 30 days, including today
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      const endDate = tomorrow.toISOString().split('T')[0];
      
      const startDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
        .toISOString().split('T')[0];

      const response = await api.post('/get_card_stats', {
        period: {
          start_date: startDate,
          end_date: endDate  // Now using tomorrow's date
        }
      });

      console.log('Stats response:', response.data); // For debugging
      setStats(response.data);
    } catch (error) {
      console.error('Error fetching stats:', error);
      setError('Failed to load dashboard statistics. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStats();
  }, []);

  const generateDateRange = (startDate, endDate) => {
    const dates = [];
    const currentDate = new Date(startDate);
    const lastDate = new Date(endDate);

    while (currentDate <= lastDate) {
      dates.push(new Date(currentDate).toISOString().split('T')[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  const chartData = (() => {
    // Generate all dates in range
    const startDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
    const endDate = new Date();
    const allDates = generateDateRange(startDate, endDate);

    // Create a map of existing stats
    const statsMap = stats.daily_stats.reduce((acc, stat) => {
      acc[stat.date] = stat;
      return acc;
    }, {});

    // Fill in missing dates with zero values
    const completeStats = allDates.map(date => ({
      date,
      cards_created: statsMap[date]?.cards_created || 0,
      cards_starred: statsMap[date]?.cards_starred || 0
    }));

    return {
      labels: completeStats.map(stat => {
        const date = new Date(stat.date);
        return date.toLocaleDateString('en-US', { 
          month: 'short', 
          day: 'numeric' 
        });
      }),
      datasets: [
        {
          label: 'Cards Created',
          data: completeStats.map(stat => stat.cards_created),
          borderColor: 'var(--primary-blue)',
          backgroundColor: 'rgba(0, 123, 255, 0.1)',
          tension: 0.4,
          fill: true,
          borderWidth: 2
        },
        {
          label: 'Cards Starred',
          data: completeStats.map(stat => stat.cards_starred),
          borderColor: '#ffc107',
          backgroundColor: 'rgba(255, 193, 7, 0.1)',
          tension: 0.4,
          fill: true,
          borderWidth: 2
        }
      ]
    };
  })();

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          padding: 20,
          font: {
            size: 12,
            family: 'var(--body-font)'
          },
          usePointStyle: true,
          boxWidth: 6
        }
      },
      title: {
        display: true,
        text: 'Daily Activity (Last 30 Days)',
        font: {
          size: 16,
          family: 'var(--heading-font)',
          weight: 600
        },
        color: 'var(--dark-gray)',
        padding: {
          top: 10,
          bottom: 20
        }
      },
      tooltip: {
        backgroundColor: 'var(--white)',
        titleColor: 'var(--dark-gray)',
        bodyColor: 'var(--text-gray)',
        borderColor: 'rgba(0, 0, 0, 0.1)',
        borderWidth: 1,
        cornerRadius: 8,
        boxPadding: 6,
        usePointStyle: true,
        boxWidth: 8,
        callbacks: {
          title: (context) => {
            const date = new Date(stats.daily_stats[context[0].dataIndex]?.date || context[0].label);
            return date.toLocaleDateString('en-US', { 
              weekday: 'long',
              month: 'long', 
              day: 'numeric'
            });
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          font: {
            size: 12,
            family: 'var(--body-font)'
          },
          color: 'var(--text-gray)'
        },
        grid: {
          color: 'rgba(0, 0, 0, 0.06)'
        },
        title: {
          display: true,
          text: 'Number of Cards',
          font: {
            size: 12,
            family: 'var(--body-font)'
          },
          color: 'var(--dark-gray)'
        },
        border: {
          dash: [4, 4]
        }
      },
      x: {
        ticks: {
          font: {
            size: 11,
            family: 'var(--body-font)'
          },
          color: 'var(--text-gray)',
          maxRotation: 45,
          minRotation: 45
        },
        grid: {
          display: false
        },
        title: {
          display: true,
          text: 'Date',
          font: {
            size: 12,
            family: 'var(--body-font)'
          },
          color: 'var(--dark-gray)'
        }
      }
    },
    elements: {
      point: {
        radius: 3,
        hoverRadius: 5,
        backgroundColor: 'var(--white)',
        borderWidth: 2
      },
      line: {
        borderWidth: 2,
        tension: 0.4
      }
    },
    interaction: {
      intersect: false,
      mode: 'index'
    },
    layout: {
      padding: 16
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: 'calc(100vh - 64px)',
        backgroundColor: 'var(--light-gray)'
      }}>
        <CircularProgress sx={{ color: 'var(--primary-blue)' }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Alert 
          severity="error" 
          sx={{ 
            mb: 3,
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            borderRadius: '12px',
            fontFamily: 'var(--body-font)'
          }}
        >
          {error}
        </Alert>
      </Container>
    );
  }

  return (
    <Box sx={{ 
      backgroundColor: 'var(--light-gray)',
      minHeight: 'calc(100vh - 64px)',
      p: { xs: 2, md: 3 }
    }}>
      <Container maxWidth="lg">
        <Typography 
          variant="h4" 
          sx={{ 
            color: 'var(--dark-gray)',
            fontSize: { xs: '1.75rem', md: '2rem' },
            fontFamily: 'var(--heading-font)',
            fontWeight: 600,
            mb: 3,
            background: 'linear-gradient(135deg, var(--primary-blue), var(--primary-green))',
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            display: 'inline-block'
          }}
        >
          Dashboard
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <StatsCard totalStats={stats.total_stats} />
          </Grid>
          
          <Grid item xs={12} md={8}>
            <Paper 
              sx={{ 
                p: 3,
                borderRadius: '12px',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                backgroundColor: 'var(--white)',
                border: '1px solid #f0f0f0',
                overflow: 'hidden'
              }}
            >
              <Line data={chartData} options={chartOptions} />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Dashboard;
