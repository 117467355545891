import React, { useState } from 'react';
import { 
  TextField, 
  Button, 
  Box, 
  Typography, 
  Link, 
  Paper, 
  Container,
  Grid,
  InputAdornment,
  IconButton,
  Divider,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Email, Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import api from '../../services/api';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/login', { email, password });
      localStorage.setItem('tokens', JSON.stringify(response.data));
      navigate('/dashboard');
    } catch (err) {
      setError('Invalid email or password');
    }
  };

  return (
    <Container maxWidth="lg" sx={{ 
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      py: 5,
      background: 'linear-gradient(135deg, rgba(240,249,255,0.8) 0%, rgba(240,255,245,0.8) 100%)'
    }}>
      <Paper elevation={3} sx={{
        borderRadius: '16px',
        overflow: 'hidden',
        width: '100%',
        maxWidth: 900,
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        boxShadow: '0 10px 30px rgba(0,0,0,0.1)',
        background: '#ffffff',
        border: '1px solid rgba(0,123,255,0.1)'
      }}>
        {/* Left side with illustration/branding */}
        <Box sx={{
          flex: '0 0 50%',
          background: 'linear-gradient(135deg, var(--primary-blue) 0%, var(--primary-green) 100%)',
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          position: 'relative',
          color: 'white',
          minHeight: isMobile ? '200px' : 'auto'
        }}>
          <Typography variant="h3" component="h1" sx={{ 
            fontWeight: 700, 
            mb: 2,
            fontFamily: 'var(--heading-font)',
            position: 'relative',
            zIndex: 2
          }}>
            Flashcard
          </Typography>
          <Typography variant="h6" sx={{ 
            mb: 3, 
            opacity: 0.9,
            fontFamily: 'var(--body-font)',
            fontWeight: 400,
            position: 'relative',
            zIndex: 2
          }}>
            Remember more. Study less.
          </Typography>
          <Typography variant="body1" sx={{ 
            mb: 4,
            opacity: 0.7,
            fontFamily: 'var(--body-font)',
            maxWidth: '90%',
            position: 'relative',
            zIndex: 2
          }}>
            Create smart flashcards automatically while you browse. Our AI-powered system helps you learn and retain information better.
          </Typography>
          
          {/* Decorative elements */}
          <Box sx={{ 
            position: 'absolute',
            top: '10%',
            right: '10%',
            width: '120px',
            height: '120px',
            borderRadius: '50%',
            background: 'rgba(255,255,255,0.1)',
            zIndex: 1
          }} />
          <Box sx={{ 
            position: 'absolute',
            bottom: '15%',
            left: '5%',
            width: '80px',
            height: '80px',
            borderRadius: '50%',
            background: 'rgba(255,255,255,0.1)',
            zIndex: 1
          }} />
        </Box>

        {/* Right side with login form */}
        <Box sx={{
          flex: '1 1 50%',
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}>
          <Typography variant="h4" component="h2" sx={{ 
            fontWeight: 600,
            mb: 1,
            color: 'var(--dark-gray)',
            fontFamily: 'var(--heading-font)'
          }}>
            Welcome Back
          </Typography>
          <Typography variant="body1" sx={{ 
            mb: 4,
            color: 'var(--text-gray)',
            fontFamily: 'var(--body-font)'
          }}>
            Sign in to access your flashcards
          </Typography>

          <form onSubmit={handleLogin}>
            <TextField
              fullWidth
              label="Email"
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email sx={{ color: 'var(--text-gray)' }} />
                  </InputAdornment>
                ),
                sx: {
                  fontFamily: 'var(--body-font)',
                  borderRadius: '8px',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.1)'
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.2)'
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--primary-blue)'
                  }
                }
              }}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Password"
              type={showPassword ? 'text' : 'password'}
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock sx={{ color: 'var(--text-gray)' }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                sx: {
                  fontFamily: 'var(--body-font)',
                  borderRadius: '8px',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.1)'
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.2)'
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--primary-blue)'
                  }
                }
              }}
              sx={{ mb: 1 }}
            />
            
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'flex-end', 
              mb: 3 
            }}>
              <Link 
                href="#" 
                underline="none"
                sx={{ 
                  color: 'var(--primary-blue)',
                  fontSize: '0.875rem',
                  fontFamily: 'var(--body-font)',
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                Forgot Password?
              </Link>
            </Box>
            
            {error && (
              <Typography 
                sx={{ 
                  mt: 1, 
                  mb: 2, 
                  color: '#f44336',
                  fontFamily: 'var(--body-font)',
                  padding: '10px',
                  borderRadius: '8px',
                  backgroundColor: 'rgba(244, 67, 54, 0.08)',
                  fontSize: '0.875rem'
                }}
              >
                {error}
              </Typography>
            )}
            
            <Button
              fullWidth
              variant="contained"
              type="submit"
              sx={{
                mt: 1,
                mb: 3,
                py: 1.5,
                borderRadius: '8px',
                background: 'linear-gradient(90deg, var(--primary-blue) 0%, var(--primary-green) 100%)',
                fontFamily: 'var(--body-font)',
                fontWeight: 600,
                textTransform: 'none',
                fontSize: '1rem',
                boxShadow: '0 4px 12px rgba(0,123,255,0.2)',
                '&:hover': {
                  background: 'linear-gradient(90deg, var(--primary-blue) 20%, var(--primary-green) 100%)',
                  boxShadow: '0 6px 16px rgba(0,123,255,0.3)',
                }
              }}
            >
              Sign In
            </Button>
          </form>
          
          <Divider sx={{ my: 3 }}>
            <Typography 
              variant="body2" 
              sx={{ 
                color: 'var(--text-gray)', 
                px: 1,
                fontFamily: 'var(--body-font)'
              }}
            >
              Don't have an account?
            </Typography>
          </Divider>
          
          <Button
            fullWidth
            variant="outlined"
            href="/register"
            sx={{
              py: 1.5,
              borderRadius: '8px',
              borderColor: 'var(--primary-blue)',
              color: 'var(--primary-blue)',
              fontFamily: 'var(--body-font)',
              fontWeight: 600,
              textTransform: 'none',
              fontSize: '1rem',
              '&:hover': {
                borderColor: 'var(--primary-green)',
                backgroundColor: 'rgba(0,200,83,0.04)'
              }
            }}
          >
            Create Account
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default Login; 