import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  LinearProgress,
  Tooltip
} from '@mui/material';
import {
  TrendingUp,
  Star,
  Language,
  LocalFireDepartment
} from '@mui/icons-material';

const StatItem = ({ icon, value, label, color }) => (
  <Box sx={{ 
    display: 'flex', 
    alignItems: 'center', 
    mb: 2,
    p: 1,
    borderRadius: '8px',
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'translateX(4px)',
      backgroundColor: `${color}08`
    }
  }}>
    <Box
      sx={{
        backgroundColor: `${color}15`,
        borderRadius: '12px',
        p: 1.5,
        mr: 2,
        display: 'flex',
        boxShadow: `0 2px 4px ${color}15`
      }}
    >
      {icon}
    </Box>
    <Box>
      <Typography 
        variant="h5" 
        component="div"
        sx={{ 
          fontWeight: 'bold',
          fontFamily: 'var(--heading-font)'
        }}
      >
        {value}
      </Typography>
      <Typography 
        variant="body2" 
        color="text.secondary"
        sx={{ 
          fontFamily: 'var(--body-font)',
          fontSize: '0.875rem'
        }}
      >
        {label}
      </Typography>
    </Box>
  </Box>
);

const StatsCard = ({ totalStats }) => {
  // Handle the case when totalStats is undefined or null
  if (!totalStats) {
    return (
      <Card 
        sx={{ 
          height: '100%',
          borderRadius: '12px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #f0f0f0',
          backgroundColor: 'var(--white)'
        }}
      >
        <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Typography variant="body1" color="text.secondary">
            No statistics available
          </Typography>
        </CardContent>
      </Card>
    );
  }

  // Handle the case when totalStats is an array (from Dashboard component)
  const statsData = Array.isArray(totalStats) ? totalStats[0] : totalStats;

  // Now destructure with defaults for safety
  const {
    total_cards = 0,
    total_starred = 0,
    total_sources = 0,
    streak_days = 0
  } = statsData || {};

  // Daily goal progress (assuming a goal of 5 cards per day)
  const dailyGoal = 5;
  const progress = (total_cards % dailyGoal) * 20; // Convert to percentage

  return (
    <Card 
      sx={{ 
        height: '100%',
        borderRadius: '12px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        border: '1px solid #f0f0f0',
        backgroundColor: 'var(--white)'
      }}
    >
      <CardContent>
        <Typography 
          variant="h6" 
          sx={{ 
            color: 'var(--dark-gray)',
            fontSize: '1.125rem',
            fontFamily: 'var(--heading-font)',
            fontWeight: 600,
            mb: 3
          }}
        >
          Overall Statistics
        </Typography>

        <StatItem
          icon={<TrendingUp sx={{ color: 'var(--primary-blue)' }} />}
          value={total_cards}
          label="Total Cards Created"
          color="var(--primary-blue)"
        />

        <StatItem
          icon={<Star sx={{ color: '#ffc107' }} />}
          value={total_starred}
          label="Starred Cards"
          color="#ffc107"
        />

        <StatItem
          icon={<Language sx={{ color: 'var(--primary-green)' }} />}
          value={total_sources}
          label="Unique Sources"
          color="var(--primary-green)"
        />

        <StatItem
          icon={<LocalFireDepartment sx={{ color: '#f44336' }} />}
          value={streak_days}
          label="Day Streak"
          color="#f44336"
        />

        <Box sx={{ mt: 3 }}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            mb: 1
          }}>
            <Typography 
              variant="body2"
              sx={{ 
                fontFamily: 'var(--body-font)',
                color: 'var(--dark-gray)'
              }}
            >
              Daily Goal Progress
            </Typography>
            <Typography 
              variant="body2" 
              sx={{ 
                fontFamily: 'var(--body-font)',
                color: 'var(--text-gray)'
              }}
            >
              {total_cards % dailyGoal}/{dailyGoal} cards
            </Typography>
          </Box>
          <Tooltip title={`${progress}% of daily goal`}>
            <LinearProgress 
              variant="determinate" 
              value={progress} 
              sx={{ 
                height: 8, 
                borderRadius: 5,
                backgroundColor: 'var(--light-gray)',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: 'var(--primary-green)'
                }
              }}
            />
          </Tooltip>
        </Box>
      </CardContent>
    </Card>
  );
};

export default StatsCard;
