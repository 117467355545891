import React, { useState, useEffect, useRef, useCallback } from 'react';
import { 
  Box, 
  Card, 
  CardContent, 
  Typography, 
  IconButton, 
  Grid,
  Divider,
  TextField,
  List,
  ListItem,
  ListItemText,
  Paper,
  Chip,
  CircularProgress,
  Container
} from '@mui/material';
import { Star, StarBorder, Delete, Search } from '@mui/icons-material';
import api from '../../services/api';

const FlashcardList = () => {
  const [cards, setCards] = useState([]);
  const [allUrls, setAllUrls] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [oldestTimestamp, setOldestTimestamp] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUrl, setSelectedUrl] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);

  // Memoize updateUrlsList function first
  const updateUrlsList = useCallback((cardsArray) => {
    const urlStats = cardsArray.reduce((acc, card) => {
      const hostname = new URL(card.url).hostname;
      acc[card.url] = acc[card.url] || {
        url: card.url,
        hostname,
        count: 0
      };
      acc[card.url].count++;
      return acc;
    }, {});
    
    setAllUrls(Object.values(urlStats));
  }, []);

  // Now define fetchCards which depends on updateUrlsList
  const fetchCards = useCallback(async (loadMore = false) => {
    try {
      // If we're already loading, don't make another request
      if (isLoading) {
        console.log("Skipping fetch as we're already loading");
        return;
      }
      
      setIsLoading(true);
      const accessToken = localStorage.getItem('access_token');
      const refreshToken = localStorage.getItem('refresh_token');
      
      // For pagination, use the oldestTimestamp which should be the timestamp of the oldest card
      let timestampParam = null;
      if (loadMore && oldestTimestamp) {
        timestampParam = oldestTimestamp;
        console.log("Loading more cards, using timestamp:", timestampParam);
      }
      
      const requestPayload = {
        request: {
          access_token: accessToken,
          refresh_token: refreshToken
        },
        pagination: {
          page_size: 15,
          before_timestamp: timestampParam
        }
      };
      
      console.log("Fetching cards with payload:", JSON.stringify(requestPayload));
      
      const response = await api.post('/get_cards', requestPayload);

      const newCards = response.data.cards || [];
      console.log(`Received ${newCards.length} cards from API`);
      
      // If we received 0 cards, there are no more to load
      if (newCards.length === 0) {
        console.log("No more cards to load");
        setHasMore(false);
      } else {
        // Only set hasMore to false if the API explicitly says there's no more
        setHasMore(response.data.has_more !== false);
      }
      
      // Use the oldest_timestamp directly from the API response
      if (response.data.oldest_timestamp) {
        console.log("API provided oldest_timestamp:", response.data.oldest_timestamp);
        setOldestTimestamp(response.data.oldest_timestamp);
      } else if (newCards.length > 0) {
        // Fallback if API doesn't provide oldest_timestamp
        console.log("API didn't provide oldest_timestamp, using the oldest from retrieved cards");
        const sortedCards = [...newCards].sort((a, b) => {
          return new Date(a.timestamp || a.created_at) - new Date(b.timestamp || b.created_at);
        });
        const oldestCard = sortedCards[0];
        const newOldestTimestamp = oldestCard.timestamp || oldestCard.created_at;
        console.log("Calculated oldest timestamp:", newOldestTimestamp);
        setOldestTimestamp(newOldestTimestamp);
      }

      // Add new cards to existing cards if loading more, otherwise replace
      setCards(prevCards => {
        const updatedCards = loadMore ? [...prevCards, ...newCards] : newCards;
        console.log(`Cards state updated. Total cards: ${updatedCards.length}`);
        return updatedCards;
      });

      // Update the URL list
      updateUrlsList(loadMore ? [...cards, ...newCards] : newCards);
      
    } catch (error) {
      console.error('Error fetching cards:', error);
      setHasMore(false); // Stop trying to load more in case of error
    } finally {
      setIsLoading(false);
    }
  }, [oldestTimestamp, cards, updateUrlsList, isLoading]);

  const observer = useRef();
  const lastCardRef = useCallback((node) => {
    if (isLoading) {
      console.log("Skipping intersection observer setup as we're loading");
      return;
    }
    
    if (observer.current) {
      console.log("Disconnecting previous intersection observer");
      observer.current.disconnect();
    }
    
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        console.log("Last card is intersecting and hasMore is true, loading more cards");
        fetchCards(true);
      } else {
        console.log(`Last card intersection: ${entries[0].isIntersecting}, hasMore: ${hasMore}`);
      }
    }, {
      threshold: 0.5 // Only trigger when at least 50% of the element is visible
    });
    
    if (node) {
      console.log("Setting up intersection observer on last card");
      observer.current.observe(node);
    }
  }, [isLoading, hasMore, fetchCards]);

  // Only fetch cards on initial mount
  useEffect(() => {
    console.log("Initial fetch of cards");
    fetchCards(false);
    
    // Cleanup intersection observer on unmount
    return () => {
      if (observer.current) {
        console.log("Cleaning up intersection observer");
        observer.current.disconnect();
      }
    };
  }, []); // Empty dependency array for initial load only

  const handleStar = async (card) => {
    try {
      const accessToken = localStorage.getItem('access_token');
      const refreshToken = localStorage.getItem('refresh_token');

      const updatedCards = cards.map(c => 
        c.id === card.id ? { ...c, is_starred: !c.is_starred } : c
      );
      
      setCards(updatedCards);

      await api.post('/update_card', {
        request: {
          access_token: accessToken,
          refresh_token: refreshToken
        },
        card: {
          id: card.id,
          is_starred: !card.is_starred
        }
      });
      
    } catch (error) {
      console.error('Error updating card star status:', error);
      // Revert the optimistic update if the API call fails
      fetchCards();
    }
  };

  const handleDelete = async (id) => {
    try {
      const accessToken = localStorage.getItem('access_token');
      const refreshToken = localStorage.getItem('refresh_token');

      setCards(cards.filter(card => card.id !== id));

      await api.post('/delete_card', {
        request: {
          access_token: accessToken,
          refresh_token: refreshToken
        },
        card_id: id
      });
      
      // Update URL list after deletion
      updateUrlsList(cards.filter(card => card.id !== id));
      
    } catch (error) {
      console.error('Error deleting card:', error);
      fetchCards();
    }
  };

  // Keep this function for future use in a refresh button feature
  // eslint-disable-next-line no-unused-vars
  const handleForceRefresh = () => {
    setCards([]);
    setOldestTimestamp(null);
    fetchCards();
  };

  const groupCardsByDate = (cards) => {
    return cards
      .filter(card => !selectedUrl || card.url === selectedUrl)
      .filter(card => !searchTerm || 
        card.selected_text.toLowerCase().includes(searchTerm.toLowerCase()) ||
        card.generated_text.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .reduce((groups, card) => {
        const timestamp = card.timestamp || card.created_at;
        
        let cardDate;
        try {
          cardDate = new Date(timestamp);
          if (isNaN(cardDate.getTime())) {
            throw new Error('Invalid date');
          }
        } catch (error) {
          console.warn('Invalid timestamp format:', timestamp);
          cardDate = new Date();
        }
        
        const date = cardDate.toLocaleDateString('en-US', { 
          month: 'long', day: 'numeric', year: 'numeric'
        });
        
        groups[date] = groups[date] || [];
      groups[date].push(card);
      return groups;
    }, {});
  };

  const getUniqueUrls = () => allUrls;

  const handleUrlClick = (url) => {
    window.open(url, '_blank');
  };

  const groupedCards = groupCardsByDate(cards);

  // Add function to handle card click and show detail view
  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  // Add function to close the detail view
  const handleCloseDetail = () => {
    setSelectedCard(null);
  };

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: { xs: 'column', md: 'row' },
        gap: 3,
        p: { xs: 2, md: 3 },
        backgroundColor: 'var(--light-gray)',
        minHeight: 'calc(100vh - 64px)',
        position: 'relative'
      }}
    >
      <Paper 
        sx={{ 
          width: { xs: '100%', md: 240 },
          height: { xs: 'auto', md: 'calc(100vh - 96px)' },
          p: 2,
          borderRadius: '12px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
          border: '1px solid #f0f0f0',
          backgroundColor: 'var(--white)',
          overflow: 'auto',
          zIndex: selectedCard ? 0 : 1
        }}
      >
        <Typography 
          variant="h6" 
          sx={{ 
            mb: 2, 
            fontFamily: 'var(--heading-font)',
            fontWeight: 600,
            color: 'var(--dark-gray)'
          }}
        >
          Sources
        </Typography>
        <List>
          <ListItem 
            button 
            onClick={() => setSelectedUrl(null)} 
            selected={!selectedUrl}
            sx={{
              borderRadius: '8px',
              mb: 0.5,
              '&.Mui-selected': {
                backgroundColor: 'rgba(0, 123, 255, 0.08)',
                '&:hover': {
                  backgroundColor: 'rgba(0, 123, 255, 0.12)'
                }
              },
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              }
            }}
          >
            <ListItemText 
              primary="All Sources" 
              primaryTypographyProps={{
                fontFamily: 'var(--body-font)',
                fontWeight: 500
              }}
            />
            <Chip 
              label={cards.length} 
              size="small" 
              sx={{
                backgroundColor: 'var(--primary-blue)',
                color: 'var(--white)',
                fontWeight: 500,
                fontSize: '0.75rem'
              }}
            />
          </ListItem>
          {getUniqueUrls().map(({ url, hostname, count }) => (
            <ListItem 
              button 
              key={url} 
              onClick={() => setSelectedUrl(url)}
              selected={url === selectedUrl}
              sx={{
                borderRadius: '8px',
                mb: 0.5,
                '&.Mui-selected': {
                  backgroundColor: 'rgba(0, 123, 255, 0.08)',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 123, 255, 0.12)'
                  }
                },
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)'
                }
              }}
            >
              <ListItemText 
                primary={hostname}
                primaryTypographyProps={{
                  fontFamily: 'var(--body-font)',
                  fontWeight: 400,
                  fontSize: '0.875rem',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap'
                }}
              />
              <Chip 
                label={count} 
                size="small" 
                sx={{
                  backgroundColor: 'rgba(0, 123, 255, 0.1)',
                  color: 'var(--primary-blue)',
                  fontWeight: 500,
                  fontSize: '0.75rem'
                }}
              />
            </ListItem>
          ))}
        </List>
      </Paper>

      <Box sx={{ 
        flex: 1,
        position: 'relative'
      }}>
        <Container maxWidth="lg">
        <Box sx={{ mb: 3 }}>
            <Typography 
              variant="h4" 
              gutterBottom
              sx={{
                fontFamily: 'var(--heading-font)',
                fontWeight: 600,
                fontSize: { xs: '1.75rem', md: '2rem' },
                color: 'var(--dark-gray)',
                mb: 2,
                background: 'linear-gradient(135deg, var(--primary-blue), var(--primary-green))',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                display: 'inline-block'
              }}
            >
            Flashcards
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search flashcards..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
                startAdornment: <Search sx={{ color: 'var(--text-gray)', mr: 1 }} />,
                sx: {
                  fontFamily: 'var(--body-font)',
                  borderRadius: '8px',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.1)'
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(0, 0, 0, 0.2)'
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--primary-blue)'
                  }
                }
            }}
            sx={{ mb: 3 }}
          />
        </Box>

        {Object.entries(groupedCards).map(([date, dateCards], index, array) => (
          <Box key={date} sx={{ mb: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    color: 'var(--text-gray)',
                    fontFamily: 'var(--heading-font)',
                    fontWeight: 500,
                    fontSize: '1rem'
                  }}
                >
                {date}
              </Typography>
              <Divider sx={{ flex: 1, ml: 2 }} />
            </Box>
            <Grid container spacing={3}>
              {dateCards.map((card, cardIndex) => (
                <Grid 
                  item 
                  xs={12} 
                  sm={6} 
                  md={4} 
                  key={card.id}
                  ref={
                    index === array.length - 1 && 
                    cardIndex === dateCards.length - 1 ? 
                    lastCardRef : null
                  }
                >
                  <Card 
                    sx={{ 
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                        transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                        borderRadius: '12px',
                        border: '1px solid #f0f0f0',
                        boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
                        overflow: 'hidden',
                        cursor: 'pointer',
                        transform: selectedCard && selectedCard.id === card.id ? 'scale(1.02)' : 'scale(1)',
                        zIndex: selectedCard && selectedCard.id === card.id ? 2 : 1,
                        position: 'relative',
                      '&:hover': {
                          transform: selectedCard && selectedCard.id === card.id ? 'scale(1.02)' : 'translateY(-4px)',
                          boxShadow: selectedCard && selectedCard.id === card.id 
                            ? '0 16px 32px rgba(0,123,255,0.15)' 
                            : '0 12px 20px rgba(0,0,0,0.1)',
                          borderColor: 'rgba(0, 123, 255, 0.3)'
                        }
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCardClick(card);
                      }}
                    >
                      <CardContent sx={{ flex: 1, p: 3 }}>
                      <Typography 
                        variant="h6" 
                        gutterBottom 
                        sx={{ 
                          display: '-webkit-box',
                            WebkitLineClamp: selectedCard && selectedCard.id === card.id ? 'none' : 3,
                          WebkitBoxOrient: 'vertical',
                            overflow: selectedCard && selectedCard.id === card.id ? 'visible' : 'hidden',
                            mb: 2,
                            fontFamily: 'var(--heading-font)',
                            fontWeight: 600,
                            fontSize: '1rem',
                            color: 'var(--dark-gray)'
                        }}
                      >
                        {card.selected_text}
                      </Typography>
                      <Typography 
                        variant="body2" 
                          sx={{ 
                            mb: 2,
                            fontFamily: 'var(--body-font)',
                            color: 'var(--text-gray)',
                            fontSize: '0.875rem',
                            display: '-webkit-box',
                            WebkitLineClamp: selectedCard && selectedCard.id === card.id ? 'none' : 4,
                            WebkitBoxOrient: 'vertical',
                            overflow: selectedCard && selectedCard.id === card.id ? 'auto' : 'hidden',
                            maxHeight: selectedCard && selectedCard.id === card.id ? '300px' : 'none'
                          }}
                      >
                        {card.generated_text}
                      </Typography>
                      <Box sx={{ mt: 'auto' }}>
                          <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 1
                          }}>
                        <Typography 
                          component="a"
                          variant="caption" 
                          sx={{ 
                                color: 'var(--primary-blue)',
                            cursor: 'pointer',
                            textDecoration: 'none',
                                fontFamily: 'var(--body-font)',
                                fontSize: '0.75rem',
                            '&:hover': {
                              textDecoration: 'underline'
                            },
                            display: 'flex',
                            alignItems: 'center',
                                gap: 0.5,
                                maxWidth: '65%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleUrlClick(card.url);
                              }}
                        >
                          <Box 
                            component="img" 
                            src={`https://www.google.com/s2/favicons?domain=${new URL(card.url).hostname}`}
                                sx={{ width: 16, height: 16, flexShrink: 0 }}
                            alt=""
                          />
                          {new URL(card.url).hostname}
                        </Typography>
                            
                            <Box sx={{ display: 'flex' }}>
                              <IconButton 
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleStar(card);
                                }} 
                                size="small"
                                sx={{
                                  color: card.is_starred ? 'var(--primary-blue)' : 'var(--text-gray)',
                                  transition: 'all 0.3s ease',
                                  '&:hover': {
                                    transform: 'scale(1.1)'
                                  }
                                }}
                              >
                                {card.is_starred ? <Star /> : <StarBorder />}
                          </IconButton>
                              <IconButton 
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDelete(card.id);
                                }} 
                                size="small"
                                sx={{
                                  color: 'var(--text-gray)',
                                  '&:hover': {
                                    color: '#f44336',
                                    transform: 'scale(1.1)'
                                  }
                                }}
                              >
                            <Delete />
                              </IconButton>
                            </Box>
                          </Box>
                          
                          {card.timestamp && (
                            <Typography
                              variant="caption"
                              sx={{
                                display: 'block',
                                color: 'var(--text-gray)',
                                fontFamily: 'var(--body-font)',
                                fontSize: '0.7rem',
                                fontStyle: 'italic'
                              }}
                            >
                              {(() => {
                                try {
                                  const date = new Date(card.timestamp);
                                  if (!isNaN(date.getTime())) {
                                    return `Created ${date.toLocaleString('en-US', {
                                      month: 'short',
                                      day: 'numeric',
                                      year: 'numeric',
                                      hour: '2-digit',
                                      minute: '2-digit'
                                    })}`;
                                  }
                                  return '';
                                } catch (e) {
                                  return '';
                                }
                              })()}
                            </Typography>
                          )}
                        </Box>
                      </CardContent>
                      
                      {selectedCard && selectedCard.id === card.id && (
                        <Box 
                          sx={{ 
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            zIndex: 3
                          }}
                        >
                          <IconButton 
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCloseDetail();
                            }}
                            size="small"
                            sx={{
                              backgroundColor: 'rgba(255,255,255,0.9)',
                              boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                              '&:hover': {
                                backgroundColor: 'white',
                                boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                              },
                              width: 28,
                              height: 28
                            }}
                          >
                            ✕
                          </IconButton>
                        </Box>
                      )}
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}

        {isLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
              <CircularProgress sx={{ color: 'var(--primary-blue)' }} />
            </Box>
          )}
          
          {!isLoading && Object.keys(groupedCards).length === 0 && (
            <Box 
              sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center', 
                justifyContent: 'center',
                py: 6,
                px: 3,
                backgroundColor: 'var(--white)',
                borderRadius: '12px',
                boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
                border: '1px solid #f0f0f0'
              }}
            >
              <Typography 
                variant="h6" 
                sx={{ 
                  color: 'var(--dark-gray)',
                  fontFamily: 'var(--heading-font)',
                  fontWeight: 500,
                  mb: 1
                }}
              >
                No flashcards found
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ 
                  color: 'var(--text-gray)',
                  fontFamily: 'var(--body-font)',
                  textAlign: 'center'
                }}
              >
                {searchTerm 
                  ? "No flashcards match your search criteria" 
                  : selectedUrl 
                    ? "No flashcards from this source" 
                    : "Create your first flashcard by selecting text on a webpage"
                }
              </Typography>
          </Box>
        )}
        </Container>
      </Box>

      {/* Add a background overlay when a card is selected for better focus */}
      {selectedCard && (
        <Box
          onClick={handleCloseDetail}
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.2)',
            zIndex: 1,
            cursor: 'pointer'
          }}
        />
      )}
    </Box>
  );
};

export default FlashcardList; 